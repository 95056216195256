import React from 'react';
import {Link} from 'react-router-dom'
import { connect } from 'react-redux';




const NavElem = ({title, type, active}) => {
    return (
        type != 'bc' && <Link to={`/${type}`}>
            <div className={`nav_elem ${active ? 'active_nav' : ''}`} data-name={type}>{title}</div>
        </Link>
    )
}



const mapDispatchToProps = dispatch => {
    return {
        setType: doctype => dispatch({type: 'FETCH_DOC', doctype})
    }
}

export default connect(null, mapDispatchToProps)(NavElem)