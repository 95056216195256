import { store } from './App'
import notie from 'notie'


// export const domain = 'https://rg.unc-esk.ru/api'
export const domain = 'https://rgdev.unc-esk.ru/api'


export function throttle(func, ms) {

    let isThrottled = false,
        savedArgs,
        savedThis;

    function wrapper() {

        if (isThrottled) {
            savedArgs = arguments;
            savedThis = this;
            return;
        }

        func.apply(this, arguments);

        isThrottled = true;

        setTimeout(function () {
            isThrottled = false;
            if (savedArgs) {
                wrapper.apply(savedThis, savedArgs);
                savedArgs = savedThis = null;
            }
        }, ms);
    }

    return wrapper;
}




// Функция получения кукисов

export function getCookie(name) {
    var matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : "";
}



// Функция записи кукисов

export function setCookie(name, value, options = {}) {

    // options = {
    //     ...options,
    //     path: '/'
    // }

    options.path = '/'
    if (options.expires instanceof Date) {
        options.expires = options.expires.toUTCString();
    }

    let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

    for (let optionKey in options) {
        updatedCookie += "; " + optionKey;
        let optionValue = options[optionKey];
        if (optionValue !== true) {
            updatedCookie += "=" + optionValue;
        }
    }

    document.cookie = updatedCookie;
}


// Функция удаления кукисов

export function deleteCookie(name) {
    setCookie(name, "", {
        'max-age': -1
    })
}


// Дефолтные заголовки с авторизацией

export function fetchHeaders() {
    return {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getCookie("access_token_rg")}`
    }
}



// Функция делает запрос по указанному url,
// callback - функция, которая подгоняет ответ 
// к нужному нам виду


export function fetchData(url, callback, errorHandler = null, method = 'GET', body = null, headers = fetchHeaders()) {
    return fetch(`${domain}${url}`, {
        method,
        headers,
        body: body
    }).then(res => {
        if (res.status === 401) {
            deleteCookie('access_token_rg')
            deleteCookie('email')
            deleteCookie('group_id')
            deleteCookie('user_id')
            store.dispatch({ type: 'SET_ACCESS_TOKEN', accessToken: null, email: null })
            if (window.location.pathname === '/login') {
                notie.alert({ type: 'error', text: 'Неверный логин и/или пароль' })
            } else {
                window.location.pathname = '/login'
            }
        } else {
            return res.json()
        }
    })
        .then(res => {
            if (res.error) {
                notie.alert({ type: 'error', text: res.error })
                if (res.errors) {
                    Object.values(res.errors).map((error, ind) => {
                        setTimeout(() => notie.alert({ type: 'error', text: error }), (ind + 1) * 3000)
                    })
                }
                throw new Error(res)
            } else {
                return callback(res)
            }
        })
        .catch(e => {
            if (errorHandler) errorHandler()
            // throw e
        })
}

function scrollTo(element, to, duration) {
    console.log(to)
    if (duration <= 0) return;
    var difference = to - element.scrollTop;
    var perTick = difference / duration * 10;

    setTimeout(function () {
        element.scrollTop = element.scrollTop + perTick;
        if (element.scrollTop === to) return;
        scrollTo(element, to, duration - 10);
    }, 10);
}


// Функция добавляет кастомный скроллбар
// content - контейнер, в котором куча элементов
// scroll - скроллбар
// container - контейнер, в котором находится скроллбар

export function addScroll(content, scroll, container) {

    let flag = false
    if (content.clientHeight === content.scrollHeight) {
        scroll.style = ''
    } else {
        scroll.style.height = container.clientHeight * content.clientHeight / content.scrollHeight + "px";
        scroll.style.top = container.clientHeight * content.scrollTop / content.scrollHeight + "px";
    }

    content.addEventListener('scroll', function (e) {
        scroll.style.top = container.clientHeight * content.scrollTop / content.scrollHeight + "px";
    })

    content.addEventListener('mouseenter', e => {
        if (content.clientHeight === content.scrollHeight) return
        scroll.style.background = '#c4c8d0'
        scroll.style.top = container.clientHeight * content.scrollTop / content.scrollHeight + "px";
        scroll.style.height = container.clientHeight * content.clientHeight / content.scrollHeight + "px";
    })
    content.addEventListener('mouseleave', e => {
        if (flag) return
        scroll.style = ''
    })

    content.addEventListener('wheel', function (e) {
        e.preventDefault()
        e.stopPropagation()
        if (e.deltaY > 0) {
            // scrollTo(content, content.scrollTop + 50, 200)
            content.scrollTop += 15
        } else {
            // scrollTo(content, content.scrollTop - 50, 200)
            content.scrollTop -= 15
        }
    })

    scroll.addEventListener('mousedown', function (start) {
        start.preventDefault();
        scroll.style.transition = 'none'
        flag = true
        let y = scroll.offsetTop;
        let onMove = function (end) {
            let delta = end.pageY - start.pageY;
            scroll.style.top = Math.min(container.clientHeight - scroll.clientHeight, Math.max(0, y + delta)) + 'px';
            content.scrollTop = (content.scrollHeight * scroll.offsetTop / container.clientHeight);
        }
        document.addEventListener('mousemove', onMove);
        document.addEventListener('mouseup', function () {
            scroll.style.transition = ''
            document.removeEventListener('mousemove', onMove);
            flag = false
        })
    })
}


// Функция для получения данных к таблице ИД (статус, счетчик)

export function getIdStatus({ d_pd_id, d_gepd_id, d_ssr_id, d_sdnf_id, d_sdrf_id, d_resd_id }) {
    const arr = [d_pd_id, d_gepd_id, d_ssr_id, d_sdnf_id, d_sdrf_id, d_resd_id]
    let counter = 0
    let subcounter = 6
    let check = false
    let reject = false
    for (let i = 0; i < arr.length; i++) {
        if (arr[i] && arr[i].status.status === "Не принят") {
            reject = true
        }
        if (arr[i] && arr[i].status.status === "На проверку") {
            check = true
        }
        if (arr[i] && arr[i].status.status === "Принят") {
            counter++
        }
        if (arr[i] && arr[i].status.status === "Отсутствует") {
            subcounter--
        }
    }
    if (reject) {
        return { name: 'Не принят', class: 'reject' }
    }
    if (check) {
        return { name: 'На проверку', class: 'check' }
    }

    return { name: `${counter}/${subcounter}`, class: '' }
}



export function resetStatuses(arr) {
    let counter = 0,
        secondCouter = 6,
        check = false,
        reject = false
    for (let i = 0; i < arr.length; i++) {
        if (arr[i] === "ne-prinyat") {
            reject = true
        }
        if (arr[i] === "na-proverku") {
            check = true
        }
        if (arr[i] === "soglasovan") {
            counter++
        }
        if (arr[i] === "otsutstvuet") {
            secondCouter--
        }
    }
    if (reject) {
        return { name: 'Не принят', class: 'reject', counter, secondCouter }
    }
    if (check) {
        return { name: 'На проверку', class: 'check', counter, secondCouter }
    }

    return { name: `${counter}/${secondCouter}`, class: '', counter, secondCouter }
}

export function browser() {
    var n = navigator.userAgent
    var arr = [['Edge/', 80], ['Edg/', 80], ['Chrome/', 75], ['Firefox/', 70]]
    var mark = false
    arr.forEach(function (elem) {
        let splited = n.split(elem[0])
        if (splited.length > 1) {
            var version = splited[1].slice(0, 2)
            if (version < elem[1]) mark = true
        }
    })
    var ie = (/trident/gi).test(n) || (/msie/gi).test(n)
    if (mark || ie) {
        setTimeout(function () {
            document.body.style.background = "#fff"
            document.body.innerHTML =
                "<div class='browser_note'>" +
                "<h1>Неподдерживаемый браузер :(</h1>" +
                "<h2>Вы можете присоединиться к нам в этих браузерах</h2>" +
                "<div class='fl'>" +
                "<div class='block ch'><p class='title'>Chrome</p><p>версии 75 или выше</p><a href='https://www.google.ru/chrome/'>Скачать</a></div>" +
                "<div class='block ff'><p class='title'>FireFox</p><p>версии 70 или выше</p><a href='https://www.mozilla.org/ru/firefox/'>Скачать</a></div>" +
                "<div class='block sf'><p class='title'>Safari</p><p>версии 13.2 или выше</p><a href='https://www.apple.com/ru/safari/'>Скачать</a></div>" +
                "</div>" +
                "<div class='fl'>" +
                "<div class='block ed'><p class='title'>Edge</p><p>версии 79 или выше</p><a href='https://www.microsoft.com/ru-ru/edge'>Скачать</a></div>" +
                "<div class='block op'><p class='title'>Opera</p><p>версии 65 или выше</p><a href='https://www.opera.com/ru'>Скачать</a></div>" +
                "</div>" +
                "</div>"
        }, 0)
    }
}

export function formArrToObj(arr, key) {
    let obj = {}
    arr.forEach(elem => obj[elem[key]] = elem)
    return obj
}

export function searchByKey(arr, key, value) {
    let ind
    for (let i = 0; i < arr.length; i++) {
        if (!ind && value == arr[i][key]) ind = i
    }

    return ind
}


export function dateFormatter(str) {
    const date = new Date(str),
        day = date.getDate(),
        month = date.getMonth() + 1,
        year = date.getFullYear(),
        hours = date.getHours(),
        minutes = date.getMinutes(),
        formated = `${day < 10 ? '0' + day : day}.${month < 10 ? '0' + month : month}.${year} ${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}`

    return formated
}

export const shortMonths = {
    0: 'января',
    1: 'февраля',
    2: 'марта',
    3: 'апреля',
    4: 'мая',
    5: 'июня',
    6: 'июля',
    7: 'августа',
    8: 'сентября',
    9: 'октября',
    10: 'ноября',
    11: 'декабря'
}

export const titleInfo = {
    users: {
        title: '',
        gist: ''
    },
    notif: {
        title: 'Объявления',
        gist: 'Предложения ДЗО в части индексов изменения сметной стоимости СМР, разработка которых необходима в первую очередь'
    },
    // meetings: {
    //     title: "Заседания",
    //     gist: ''
    // },
    nomen: {
        title: 'Номенклатура',
        gist: 'Сборника «Укрупненные показатели стоимости строительства. Объекты энергетики. Электросетевые объекты»'
    },
    muncs: {
        title: 'Методика разработки',
        gist: 'По разработке укрупненных нормативов цены типовых технологических решений капитального строительства объектов электроэнергетики'
    },
    bc: {
        title: 'Бюллетень цен',
        gist: ''
    },
    rm: {
        title: 'Ресурсная модель',
        gist: ''
    },
    tzindex: {
        title: 'ТЗ индексы',
        gist: ''
    },
    ispoldocums: {
        title: 'Исходные данные',
        gist: 'Норматива ценовой стоимости'
    }
}


export const notTextContentArr = ['ispoldocums', 'meetings', 'notif', 'users']
export const notConfirmArr = ['ispoldocums', 'notif', 'users']


export const roles = [
    {
        id: 1,
        name: "Администратор",
        slug: "administrator",
        description: "Администратор системы"
    },
    {
        id: 2,
        name: "Менеджер РГ",
        slug: "menedzer-rg",
        description: "Менеджер системы. Может создавать ИД, апрувить загруженные документы."
    },
    {
        id: 3,
        name: "Исполнитель",
        slug: "ispolnitel",
        description: "Исполнитель. Может добавлять документы в рамках своей группы."
    },
    {
        id: 4,
        name: "Наблюдатель",
        slug: "nablyudatel",
        description: "Имеет доступ только на просмотр."
    },
]