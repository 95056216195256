const initialState = {
    id: null,
    fip: null,
    bottom: null
}


export function fipchangereducer(state = initialState, action) {
    switch (action.type) {
        case 'SET_FIP_CHANGE':
            return {
                id: action.id,
                fip: action.fip,
                bottom: action.bottom
            }
        case 'CLEAR_FIP_CHANGE':
            return {
                id: null,
                fip: null,
                bottom: null
            }
        default:
            return state
    }
}