import React, { useRef, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getCookie, domain } from '../publickFunc'
import { Collapse } from '@material-ui/core'

function getSrc(ext, id, url_file) {
    if (ext == 'doc' || ext == 'xlsx') {
        return `https://view.officeapps.live.com/op/embed.aspx?src=${url_file ? url_file : `${domain}/document/download/${id}`}?api_token=${getCookie("access_token_rg")}`
    } else {
        return `https://docs.google.com/viewer?url=${url_file ? url_file : `${domain}/document/download/${id}`}?api_token=${getCookie("access_token_rg")}&embedded=true`
    }
}


const TextContent = ({ file, loadDoc, doctype, selectedMonth, selectMonth }) => {
    const [withPreload, setPreload] = useState(true)
    const [time, setTime] = useState(null)
    let frame = useRef(null)

    useEffect(() => {
        if (time) clearInterval(time)
        let timer
        let inLoad = true
        setPreload(true)
        if (frame.current && file) {
            frame.current.onload = () => {
                inLoad = false
                setPreload(false)
            }
            timer = setInterval(() => {
                if (inLoad) {
                    frame.current.src = getSrc(file.ext, file.id)
                } else {
                    clearInterval(timer)
                }
            }, 3000)
            setTime(timer)
        }
        return () => clearInterval(timer)

    }, [file])


    return (
        <div className="text_content">
            {doctype === 'rm' ? (
                <ul>
                    <div className={`rm_month_name${selectedMonth === 1 ? ' selected' : ''}`} onClick={() => {
                        if (selectedMonth !== 1) selectMonth(1)
                    }}>Май</div>
                    <Collapse in={selectedMonth === 1} timeout="auto">
                        <li><a href="https://drive.google.com/drive/folders/14K6XScG5gWgoZVyuNarGZMRz3W3mWlnC" target="_blank">В1 (рабочий шифр)</a></li>
                        <li><a href="https://drive.google.com/drive/folders/1mL36qfmQXx4vcf7iJpCFE1K_Gjyizexm" target="_blank">В2 (рабочий шифр)</a></li>
                        <li><a href="https://drive.google.com/drive/folders/1jKgQDbydaUNVx1c3FFqDpd3cnNIksp1r" target="_blank">В3 (рабочий шифр)</a></li>
                        <li><a href="https://drive.google.com/drive/folders/1n2w2YOHA8QMPR61pyr54PDCv_VGqqtjS" target="_blank">В4 (рабочий шифр)</a></li>
                        <li><a href="https://drive.google.com/drive/folders/1hK--0dL1G5B5wF6MctXZVoSXMy3IFtfS" target="_blank">З1 (рабочий шифр)</a></li>
                        <li><a href="https://drive.google.com/drive/folders/1BVYf1_EWuKBsO4i33tgELNHTe_kv3nlL" target="_blank">З2 (рабочий шифр)</a></li>
                        <li><a href="https://drive.google.com/drive/folders/1N_mDhE7DaCG1zrUifrXTxj_wGy6Q6-2Q" target="_blank">З3 (рабочий шифр)</a></li>
                        <li><a href="https://drive.google.com/drive/folders/1NaMPxqAE0PylSSZPrlGld13-2t7bNejJ" target="_blank">З4 (рабочий шифр)</a></li>
                        <li><a href="https://drive.google.com/drive/folders/1_BB8o5yX8xNg1fZxsDyJLYw-5oTcj1bP" target="_blank">З5 (рабочий шифр)</a></li>
                        <li><a href="https://drive.google.com/drive/folders/1CefuBUAtrOOOqr2-Grut29X-bBHAmJFW" target="_blank">Т1 (рабочий шифр)</a></li>
                        <li><a href="https://drive.google.com/drive/folders/1vDYTlDw3xtMdsF34C5WRVNc4dux-PM1y" target="_blank">Т2 (рабочий шифр)</a></li>
                        <li><a href="https://drive.google.com/drive/folders/1xxj8ibKbYpE8qBMHiLeRTeGKJHk-hIa1" target="_blank">Т3 (рабочий шифр)</a></li>
                        <li><a href="https://drive.google.com/drive/folders/1JbbHLf0yLcIh02tzU5eEPWs3-K3fp-vN" target="_blank">Т4 (рабочий шифр)</a></li>
                    </Collapse>
                    <div className={`rm_month_name${selectedMonth === 2 ? ' selected' : ''}`} onClick={() => {
                        if (selectedMonth !== 2) selectMonth(2)
                    }}>Июнь</div>
                    <Collapse in={selectedMonth === 2} timeout="auto">
                        <li><a href='https://drive.google.com/drive/folders/1mFf-Lp1i1j3i8LwT7FsAxH0KqVO__REi' target='_blank'>НЦС 12-05-001 (часть 1)</a></li>
                        <li><a href='https://drive.google.com/drive/folders/1MLKshv2_7F9sct60HXgpRBJxwonyzpIm' target='_blank'>НЦС 12-05-002 (часть 1)</a></li>
                        <li><a href='https://drive.google.com/drive/folders/1tFVg2dhU9aOvvKpIobkQqF5UbKNAKFFD' target='_blank'>НЦС 12-05-003 (часть 1)</a></li>
                        <li><a href='https://drive.google.com/drive/folders/1wQS_LfGYIzk3IYmIxJa-4UfvknyBR4QK' target='_blank'>НЦС 12-05-004 (часть 1)</a></li>
                        <li><a href='https://drive.google.com/drive/folders/1ZYvrC1WM5L3MHIzcySymW2ys2J0MnMQi' target='_blank'>НЦС 12-05-005 (часть 1)</a></li>
                        <li><a href='https://drive.google.com/drive/folders/1DTfgaEFvVepyEHFS4vKFGhIbBXSxOfep' target='_blank'>НЦС 12-05-006 (часть 1)</a></li>
                        <li><a href='https://drive.google.com/drive/folders/1JCskGPHM0Z-BOWK1LEE804ibV2JdYF7c' target='_blank'>НЦС 12-05-008 (часть 1)</a></li>
                        <li><a href='https://drive.google.com/drive/folders/1iT0eHzwKOLplnOeiHq4HhXgJ9A7kdzMJ' target='_blank'>НЦС 12-05-009 (часть 1)</a></li>
                        <li><a href='https://drive.google.com/drive/folders/1N9Ui4C462huvBEg5GAn7H4mQ07U6QaEf' target='_blank'>НЦС 12-05-011 (часть 1)</a></li>
                        <li><a href='https://drive.google.com/drive/folders/1xxO2yKhNFerOVorG7hOoq4dVBiKfcXcK' target='_blank'>НЦС 12-06-001 (часть 1)</a></li>
                        <li><a href='https://drive.google.com/drive/folders/1v7lzLxVCyXMXHJV1RdSFOYdbWuyslO34' target='_blank'>НЦС 12-06-002 (часть 1)</a></li>
                        <li><a href='https://drive.google.com/drive/folders/19pkfxbry_iKshVOHLfWatqHblyv3yHzC' target='_blank'>НЦС 12-06-003 (часть 1)</a></li>
                        <li><a href='https://drive.google.com/drive/folders/1SkLcrnGsYOE4LeTS0ldB8a1khIDZFyQq' target='_blank'>НЦС 12-06-004 (часть 1)</a></li>
                        <li><a href='https://drive.google.com/drive/folders/1MYY-ycCxPisx11Zm7ARzN1OhsXLBDFMF' target='_blank'>НЦС 21-05-001 (часть 1)</a></li>
                        <li><a href='https://drive.google.com/drive/folders/1iW1Byd1QISNANrundfPeAZMFW-ryYh3v' target='_blank'>НЦС 21-05-002 (часть 1)</a></li>
                        <li><a href='https://drive.google.com/drive/folders/1_zGvekKS1WD2-jg6nxFgE63LV-NbFFpN' target='_blank'>НЦС 21-05-003 (часть 1)</a></li>
                        <li><a href='https://drive.google.com/drive/folders/1QLHZsY2NRaBSuYSuhDW5bjGwUa4Op5UX' target='_blank'>НЦС 21-05-004 (часть 1)</a></li>
                        <li><a href='https://drive.google.com/drive/folders/15EewNZf-QjHG0BJyWEuDrkoyTthLPAq0' target='_blank'>НЦС 21-05-006 (часть 1)</a></li>
                        <li><a href='https://drive.google.com/drive/folders/18OKMIWB9U2w4VvkgGsZ-Y3fhoZON1LST' target='_blank'>НЦС 21-05-007 (часть 1)</a></li>
                        <li><a href='https://drive.google.com/drive/folders/1s-WgbONqNrUJN_8Hdgh7PXe3aPcLb4Q0' target='_blank'>НЦС 21-05-008 (часть 1)</a></li>
                        <li><a href='https://drive.google.com/drive/folders/1_-qZpafPQkHT_WsiM9ZKbCkcAkt_Dv8s' target='_blank'>НЦС 21-05-009 (часть 1)</a></li>
                    </Collapse>
                    <div className={`rm_month_name${selectedMonth === 3 ? ' selected' : ''}`} onClick={() => {
                        if (selectedMonth !== 3) selectMonth(3)
                    }}>Июль</div>
                    <Collapse in={selectedMonth === 3} timeout="auto">
                        <li><a href="https://drive.google.com/drive/folders/1igQbLFOyQHc4OcxmMv0Jlg8MUnD5L1Ca" target="_blank">НЦС ХХ-ХХ-ХХХ Устройство лежневых дорог</a></li>
                        <li><a href="https://drive.google.com/drive/folders/1tkFMZQJd6DXhXPGdPkSWtzkN4DobQzgF" target="_blank">НЦС ХХ-ХХ-ХХХ Устройство защиты опор</a></li>
                        <li><a href="https://drive.google.com/drive/folders/18cBhqGhkMNw5-hPPyFzHN0y9K0Ow8BvE" target="_blank">НЦС ХХ-ХХ-ХХХ Трелевка древесины</a></li>
                        <li><a href="https://drive.google.com/drive/folders/1kQ-SPmvXQ-3H8bQaCcQqcBwQuOSnyNXS" target="_blank">НЦС ХХ-ХХ-ХХХ Переустройство магистрального газопровода </a></li>
                        <li><a href="https://drive.google.com/drive/folders/1DyQmnFEbteG4np0MxWSKT7eKDbgOPRWB" target="_blank">НЦС 12-06-004</a></li>
                        <li><a href="https://drive.google.com/drive/folders/1kBwBz6ufCqOoq6RlU2yWKqb1Ux1yM58z" target="_blank">НЦС 12-06-003</a></li>
                        <li><a href="https://drive.google.com/drive/folders/1-ON0C2870zMuwLahRNNXQnK9ftL9m890" target="_blank">НЦС 12-06-002</a></li>
                        <li><a href="https://drive.google.com/drive/folders/1RXTvMoAvoUEVQaRJdTIvfv3zM5yzxjPC" target="_blank">НЦС 12-06-001</a></li>
                        <li><a href="https://drive.google.com/drive/folders/14IZmlVranGsjr34S3_uxjkvEySagh7ls" target="_blank">НЦС 12-05-012</a></li>
                        <li><a href="https://drive.google.com/drive/folders/1Vq9l6N_M5iFSQsZjX5Y6JpCl2rC-omoc" target="_blank">НЦС 12-05-011</a></li>
                        <li><a href="https://drive.google.com/drive/folders/1hzvU-zEkpRx7UuSVP4Un0SBafD7BrjDw" target="_blank">НЦС 12-05-010</a></li>
                        <li><a href="https://drive.google.com/drive/folders/1h8MD6zWwo2Q6Dory4GzSPXk9a2VvbR-P" target="_blank">НЦС 12-05-009</a></li>
                        <li><a href="https://drive.google.com/drive/folders/1VOK00PGwPq26Ejlh_oYZyJylbQ04UzIY" target="_blank">НЦС 12-05-008</a></li>
                        <li><a href="https://drive.google.com/drive/folders/1y_aPZdws3NoblaD0qs0C-2jX_CgO-1LN" target="_blank">НЦС 12-05-007</a></li>
                        <li><a href="https://drive.google.com/drive/folders/1juRKhoRWjqap6EGivCm_CWfyJdVMB2NV" target="_blank">НЦС 12-05-006</a></li>
                        <li><a href="https://drive.google.com/drive/folders/1vhvgbcSt9fgDkLFFcRiADQG21oo1IUkA" target="_blank">НЦС 12-05-005</a></li>
                        <li><a href="https://drive.google.com/drive/folders/1y4bnReephuruZoVIYsr8-apigQ95vD4d" target="_blank">НЦС 12-05-004</a></li>
                        <li><a href="https://drive.google.com/drive/folders/1edafhOJN_sHQzUifOVXv5um-214GJ-AJ" target="_blank">НЦС 12-05-003</a></li>
                        <li><a href="https://drive.google.com/drive/folders/1cMUx9Ck4_CkkFozXIKsjx4jlcktxUH0z" target="_blank">НЦС 12-05-002</a></li>
                        <li><a href="https://drive.google.com/drive/folders/1Qa0RnUWi-cB3dmnB9_E8VbuWARXHr2u6" target="_blank">НЦС 12-05-001</a></li>
                    </Collapse>
                </ul>
            ) : (
                    <React.Fragment>
                        <div className="file_container">
                            <div>
                                <label className="btn">
                                    <input type="file" onChange={(e) => {
                                        setPreload(true)
                                        let formData = new FormData()
                                        formData.append('file', e.currentTarget.files[0])
                                        loadDoc(doctype, file.id, formData)
                                    }} />
                            Добавить файл
                        </label>
                            </div>
                        </div>
                        <div className={`watch_file ${(file && withPreload) ? 'preload_comment' : ''}`}>
                            {file && file.file_name && <iframe title="frame" ref={frame} src={getSrc(file.ext, file.id, file.url_file)}></iframe>}
                        </div>
                    </React.Fragment>
                )}
        </div>
    )
}

const mapStateToProps = state => {
    return {
        document: state.document.document,
        file: state.document.file,
        selectedMonth: state.document.selectedRmMonth
    }
}

const mapDispatchToProps = dispatch => {
    return {
        loadDoc: (doctype, id, formData) => dispatch({ type: 'LOAD_DOC', doctype, id, formData }),
        selectMonth: (month) => dispatch({ type: 'SET_SELECTED_RM_MONTH', month })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TextContent)

