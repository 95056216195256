import { takeLatest, takeEvery, all, call, put } from 'redux-saga/effects'
import { getCookie, fetchHeaders, fetchData, formArrToObj, searchByKey } from "../../publickFunc";
import { store } from '../../App'
import notie from 'notie'





const statusById = {
    1: 'ne-zagruzen',
    2: 'otsutstvuet',
    3: 'na-proverku',
    4: 'ne-prinyat',
    5: 'soglasovan'
}

const statusObj = {
    'ne-zagruzen': {
        status: "Не загружен",
        id: 1
    },
    "otsutstvuet": {
        status: "Отсутствует",
        id: 2
    },
    "na-proverku": {
        status: "На проверку",
        id: 3
    },
    "ne-prinyat": {
        status: "Не принят",
        id: 4
    },
    "soglasovan": {
        status: "Согласован",
        id: 5
    },
}


function* watchLogin() {
    yield takeLatest('LOGIN', fetchAccessToken)
}

function* watchFetchDoc() {
    yield takeLatest('FETCH_DOC', fetchDoc)
}

function* watchSendDoc() {
    yield takeLatest('LOAD_DOC', loadDoc)
}

function* watchFetchUsers() {
    yield takeLatest('FETCH_USERS', fetchUsers)
}

function* watchFetchOrgs() {
    yield takeLatest('FETCH_ORGS', fetchOrgs)
}

function* watchFetchComments() {
    yield takeLatest('FETCH_COMMENTS', fetchComments)
}

function* watchSendComment() {
    yield takeLatest('SEND_COMMENT', sendComment)
}

function* watchAddVoter() {
    yield takeEvery('ADD_VOTER', addVoter)
}

function* watchRemoveVoter() {
    yield takeEvery('REMOVE_VOTER', removeVoter)
}

function* watchChangeConfirm() {
    yield takeEvery('CHANGE_CONFIRM', changeConfirm)
}

function* watchChangeDocStatus() {
    yield takeEvery('CHANGE_DOC_STATUS', changeDocStatus)
}

function* watchUploadFile() {
    yield takeEvery('UPLOAD_FILE', uploadFile)
}

function* watchDeleteFile() {
    yield takeEvery('DELETE_FILE', deleteFile)
}

function* watchAddTitul() {
    yield takeEvery('ADD_TITUL', addTitul)
}

function* watchSearchDocs() {
    yield takeLatest('SEARCH_DOCS', searchDocs)
}

function* watchAppendSearchDocs() {
    yield takeLatest('APPEND_SEARCH_DOCS', appendSearchDocs)
}

function* watchSaveFipChange() {
    yield takeLatest('SAVE_FIP_CHANGE', saveFipChange)
}

function* watchDeleteFip() {
    yield takeLatest('DELETE_FIP', deleteFip)
}

function* watchFetchSubInfo() {
    yield takeLatest('FETCH_SUB_INFO', fetchSubInfo)
}

function* watchFetchUserSubs() {
    yield takeLatest('GET_USER_SUBS', fetchUserSubs)
}

function* watchSubUser() {
    yield takeEvery('SUB_USER', subUser)
}

function* watchUnsubUser() {
    yield takeEvery('UNSUB_USER', unsubUser)
}

function* watchAppendNotifList() {
    yield takeLatest('APPEND_NOTIF_LIST', appendNotifList)
}

function* watchWatchNotif() {
    yield takeEvery('WATCH_NOTIF', watchNotif)
}

function* watchGetOffers() {
    yield takeLatest('GET_OFFERS', getOffers)
}


function* watchDeleteOffer() {
    yield takeEvery('DELETE_OFFER', deleteOffer)
}

function* watchCreateOffer() {
    yield takeLatest('CREATE_OFFER', createOffer)
}

function* watchAddGroupVoting() {
    yield takeEvery('ADD_GROUP_VOTING', addGroupVoting)
}

function* watchRemoveGroupVoting() {
    yield takeEvery('REMOVE_GROUP_VOTING', removeGroupVoting)
}

function* watchSetVotingVal() {
    yield takeEvery('SET_VOTING_VAL', setVotingVal)
}

function* watchCreateUser() {
    yield takeLatest('CREATE_USER', createUser)
}

function* wacthChangeUser() {
    yield takeLatest('CHANGE_USER', changeUser)
}

// function* watchCreateOrg() {
//     yield takeLatest('CREATE_ORG', createOrg)
// }

// function* wacthChangeOrg() {
//     yield takeLatest('CHANGE_ORG', changeOrg)
// }

function* watchUserSelfSub() {
    yield takeEvery('USER_SELF_SUB', userSelfSub)
}

function* watchChangeCompIds() {
    yield takeEvery('CHANGE_USER_COMPANIES', changeUserCompIds)
}

function* watchReadIdComments() {
    yield takeLatest('READ_ID_COMMENTS', readIdComments)
}


export default function* rootSaga() {
    yield all([
        watchLogin(),
        watchFetchDoc(),
        watchSendDoc(),
        watchFetchUsers(),
        watchFetchOrgs(),
        watchFetchComments(),
        watchSendComment(),
        watchAddVoter(),
        watchRemoveVoter(),
        watchChangeConfirm(),
        watchChangeDocStatus(),
        watchUploadFile(),
        watchDeleteFile(),
        watchAddTitul(),
        watchSearchDocs(),
        watchAppendSearchDocs(),
        watchSaveFipChange(),
        watchDeleteFip(),
        watchFetchSubInfo(),
        watchFetchUserSubs(),
        watchSubUser(),
        watchUnsubUser(),
        watchAppendNotifList(),
        watchWatchNotif(),
        watchGetOffers(),
        watchDeleteOffer(),
        watchCreateOffer(),
        watchAddGroupVoting(),
        watchRemoveGroupVoting(),
        watchSetVotingVal(),
        watchCreateUser(),
        wacthChangeUser(),
        // watchCreateOrg(),
        // wacthChangeOrg(),
        watchUserSelfSub(),
        watchChangeCompIds(),
        watchReadIdComments(),
    ])
}



// Сага загрузки и записи токена

function* fetchAccessToken({ email, password }) {
    try {
        let formData = new FormData()
        formData.append('email', email)
        formData.append('password', password)

        const res = yield call(() => fetchData('/login', res => res.data, null, 'POST', formData, { 'X-Requested-With': 'XMLHttpRequest' }))

        let maxRole = res.roles.map(elem => elem.id).sort()
        maxRole = maxRole[0]

        // Запись данных в куки при успешном ответе
        document.cookie = `access_token_rg=${res.api_token}; path=/; expires=1584200266`
        document.cookie = `email=${res.email}; path=/; expires=1584200266`
        document.cookie = `user_name=${res.sname} ${res.name[0]}.${res.lname[0]}.; path=/; expires=1584200266`
        document.cookie = `max_role=${maxRole}; path=/; expires=1584200266`
        document.cookie = `group_id=${res.group_id}; path=/; expires=1584200266`
        document.cookie = `user_id=${res.id}; path=/; expires=1584200266`

        // Запись данных в стор и обновление url
        yield put({ type: 'SET_ACCESS_TOKEN', accessToken: res.api_token, email: res.email, userName: `${res.sname} ${res.name[0]}.${res.lname[0]}.`, maxRole, userGroupId: res.group_id })
        yield put({ type: 'SET_USER_ID', currentUserId: res.id })
        yield call(() => window.location.pathname = '/nomen')

    } catch (err) {
        console.log(err)
    }
}

//Сага получения документа

function* fetchDoc({ doctype, ofset = 0 }) {
    try {
        if (doctype === 'ispoldocums') {
            let docums = yield call(() => fetchData(`/ispoldocums?sort=desc&order=updated_at&limit=15&offset=${ofset * 15}&with=commentNotifications`, res => res))
            let docsFromState = store.getState().ispoldocums.docs

            if (docsFromState) {

                let result = docsFromState.slice().concat(docums)
                yield put({ type: 'ADD_ISPOLDOCUMS', docums: result })
            } else {

                yield put({ type: 'ADD_ISPOLDOCUMS', docums })
            }
            yield put({ type: 'SET_DOC', document: null, file: null, doctype, confirmList: [] })
        } else {
            yield put({ type: 'REMOVE_FRAME' })
            const res = yield all({
                docInfo: call(() => fetchData(`/${doctype === 'notif' ? 'nomen' : doctype}docums/${doctype === 'notif' ? '5' : '1'}`, res => res)),
                confirm: call(() => fetchData(`/votings?type=${doctype}docums&part_id=${doctype === 'rm' ? store.getState().document.selectedRmMonth : 1}`, res => res))
            })
            let file
            for (let key in res.docInfo) {
                if (typeof res.docInfo[key] === 'object') {
                    file = res.docInfo[key]
                    delete res.docInfo[key]
                }
            }
            yield put({ type: 'SET_DOC', document: res.docInfo, file, doctype, confirmList: res.confirm })
        }
    } catch (err) {
        console.log(err)
    }
}


function* fetchSubInfo() {
    try {
        const { docTypes, docActions, documentEventTypes, list } = {
            docTypes: yield call(() => fetchData('/v2/document-types', res => res.documentTypes)),
            docActions: yield call(() => fetchData('/v2/document-actions', res => res.documentActions)),
            documentEventTypes: yield call(() => fetchData('/v2/document-event-types?with=documentType,documentAction', res => res.documentEventTypes)),
            list: yield call(() => fetchData('/v2/notifications?offset=0&limit=20', res => res.notifications))

        }
        yield put({ type: 'SET_SUB_INFO', docTypes, docActions: formArrToObj(docActions, 'id'), documentEventTypes: formArrToObj(documentEventTypes, 'id') })
        yield put({ type: 'SET_NOTIF_LIST', notifList: list })
    } catch (err) {
        console.log(err)
    }
}

function* appendNotifList({ ofset }) {
    try {
        const notifs = yield call(() => fetchData(`/v2/notifications?offset=${ofset * 20}&limit=20`, res => res.notifications))
        let list = store.getState().notif.notifList.slice()
        let notifList = list.concat(notifs)
        yield put({ type: 'SET_NOTIF_LIST', notifList })
    } catch (err) {
        console.log(err)
    }
}


function* watchNotif({ id }) {
    const res = yield call(() => fetchData(`/v2/notifications/${id}`, res => res, null, 'PATCH'))
    let list = store.getState().notif.notifList.slice()
    let ind
    for (let i = 0; i < list.length; i++) {
        if (!ind && id == list[i].id) ind = i
    }
    list[ind].read_at = true
    yield put({ type: 'SET_NOTIF_LIST', notifList: list })
}

//Сага перезагрузки документа

function* loadDoc({ doctype, id, formData }) {
    try {
        yield put({ type: 'REMOVE_FRAME' })
        const res = yield call(() => fetchData(`/document/${id}?status_id=1&type_docums=d_${doctype}_id`, res => res, null, 'POST', formData, {
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': `Bearer ${getCookie("access_token_rg")}`
        }))
        yield put({ type: 'UPDATE_DOCUMENT', file: res })
    } catch (err) {
        console.log(err)
    }
}



//Сага загрузки юзеров

function* fetchUsers() {
    try {
        const { users, orgs, companies } = yield all({
            users: call(() => fetchData('/users', res => res)),
            orgs: call(() => fetchData('/usergroups', res => res)),
            companies: call(() => fetchData('/v2/companies', res => res.companys))
        })
        const lowRoles = users.filter(elem => {
            return (elem.roles[0] && elem.roles[0].id > 2)
        })
        yield put({ type: 'ADD_ORGS', orgs })
        yield put({ type: 'ADD_COMPANIES', companies })
        yield put({ type: 'SET_CURRENT_COMP', currentComp: companies.find(elem => elem.group_id) })
        yield put({ type: 'ADD_USERS', userList: users, lowRoles })
        let currentUser = users.find(elem => elem.id == store.getState().users.currentUserId)
        yield put({ type: 'SET_CURRENT_USER', currentUser })
        yield put({ type: 'GET_USER_SUBS', userId: currentUser.id })
    } catch (err) {
        console.log(err)
    }
}

function* createUser({ sname, name, lname, email, roleId, company_ids, password, confirmPassword }) { //here
    try {
        let body = {
            sname,
            name,
            lname,
            email,
            role_id: roleId,
            company_ids,
            password,
            password_confirmation: confirmPassword
        }
        const user = yield call(() => fetchData('/users', res => res, null, 'POST', JSON.stringify(body)))
        if (user) {
            let users = store.getState().users.userList.slice()
            users.push(user)
            const lowRoles = users.filter(elem => {
                return (elem.roles[0] && elem.roles[0].id > 2)
            })
            yield put({ type: 'ADD_USERS', userList: users, lowRoles })
            yield put({ type: 'SET_CURRENT_USER', currentUser: user })
            yield put({ type: 'ADD_NEW_USER_MODE', isNew: false })
            notie.alert({ type: 'success', text: 'Пользователь успешно добавлен' })
        }
    } catch (err) {
        console.log(err)
    }
}

function* changeUser({ id, sname, name, lname, email, roleId, company_ids, password, confirmPassword }) {
    try {
        let body = {
            sname,
            name,
            lname,
            email,
            role_id: roleId,
            company_ids,
            password,
            password_confirmation: confirmPassword
        }
        if (password === '') {
            delete body.password
        }
        if (confirmPassword === '') {
            delete body.password_confirmation
        }
        const user = yield call(() => fetchData(`/users/${id}`, res => res, null, 'PUT', JSON.stringify(body)))
        if (user) {
            let users = store.getState().users.userList.slice()
            let ind = users.findIndex(elem => elem.id == id)
            users.splice(ind, 1, user)
            const lowRoles = users.filter(elem => {
                return (elem.roles[0] && elem.roles[0].id > 2)
            })
            let currentUserId = store.getState().auth.userGroupId
            if (user.id == currentUserId) {
                yield put({
                    type: 'UPDATE_USER_INFO',
                    email: user.email,
                    userName: `${user.sname} ${user.name[0]}.${user.lname[0]}.`,
                    maxRole: user.roles[0].id,
                    userGroupId: user.group_id
                })
            }
            yield put({ type: 'ADD_USERS', userList: users, lowRoles })
            yield put({ type: 'SET_CURRENT_USER', currentUser: user })
            notie.alert({ type: 'success', text: 'Данные пользователя успешно изменены' })
        }
    } catch (err) {
        console.log(err)
    }
}


//Сага загрузки организаций

function* fetchOrgs() {
    try {
        const { groups, advertVotings, groupVotings } = yield all({
            groups: call(() => fetchData('/v2/groups', res => res.groups)),
            advertVotings: call(() => fetchData('/v2/proposal-voting-types', res => res.advertVotingTypes)),
            groupVotings: call(() => fetchData('/v2/proposal-group-votings', res => res.advertGroupVotings))
        })
        yield put({ type: 'ADD_GROUPS', groups, advertVotings, groupVotings })
    } catch (err) {
        console.log(err)
    }
}



//Сага загрузки комментов  

function* fetchComments({ partId, isOffer, offset = 0 }) {
    try {
        yield put({ type: 'COMMENT_IN_LOAD', inLoad: true })
        let doctype = store.getState().document.doctype,
            commentList = store.getState().comments.commentList.slice(),
            res
        if (doctype == 'ispoldocums') {
            res = yield call(() => fetchData(`/comments?part_id=${partId}&type=ispoldocums&order=id&sort=desc&offset=${offset * 50}&limit=50`, res => res))
        } else if (doctype === 'notif') {
            res = yield call(() => fetchData(`/comments?part_id=5&type=nomendocums&order=id&sort=desc&offset=${offset * 50}&limit=50`, res => res))
        } else if (isOffer) {
            res = yield call(() => fetchData(`/comments?part_id=${partId}&type=offer&order=id&sort=desc&offset=${offset * 50}&limit=50`, res => res))
        } else {
            res = yield call(() => fetchData(`/comments?part_id=${doctype === 'rm' ? store.getState().document.selectedRmMonth : 1}&type=${doctype}docums&order=id&sort=desc&offset=${offset * 50}&limit=50`, res => res))
        }
        // let comments = commentList.concat(res)
        yield put({ type: 'ADD_COMMENTS', comments: res })
        yield put({ type: 'COMMENT_IN_LOAD', inLoad: false })
    } catch (err) {
        console.log(err)
    }
}

//Сага отправки комментария


function* sendComment({ text, isOffer, offerId }) {
    try {

        let body
        let activeTr = store.getState().ispoldocums.activeTr
        let type = store.getState().document.doctype
        let partId = (activeTr && type === 'ispoldocums') ? activeTr.id : 1
        let parent = store.getState().comments.parentComment
        let isOffer = store.getState().offer.offerCommentMode

        if (!isOffer) {

            body = { body: JSON.stringify(text), type: type === 'ispoldocums' ? 'ispoldocums' : type === 'notif' ? 'nomendocums' : `${type}docums`, part_id: type == 'notif' ? 5 : type === 'rm' ? store.getState().document.selectedRmMonth : partId }
        } else {
            body = { body: JSON.stringify(text), type: 'offer', part_id: offerId }
        }
        if (parent) body.parent_id = parent.id

        const comment = yield call(() => fetchData('/comments', res => res, null, 'POST', JSON.stringify(body)))

        if (!comment) return

        let comments = store.getState().comments.commentList.slice()

        if (parent) {
            let parentId = comment.parent_id,
                ind = searchByKey(comments, 'id', parentId)
            if (!comments[ind].childs) comments[ind].childs = []

            comments[ind].childs.push(comment)

        } else {
            comments.unshift(comment)
        }

        yield put({ type: 'ADD_COMMENTS', comments })
        yield put({ type: 'SET_OFFER_COMMENT_MODE', mode: false })
    } catch (err) {
        console.log(err)
    }
}


//Сага добавления в лист согласования

function* addVoter({ userId }) {
    try {
        const type = store.getState().document.doctype
        let newElem = yield call(() => fetchData(`/votings?user_id=${userId}&status_id=1&type=${store.getState().document.doctype}docums&part_id=${type === 'rm' ? store.getState().document.selectedRmMonth : 1}`, res => res, null, 'POST'))
        newElem.status = {
            id: 1,
            status: "Ожидаем",
            slug: "ozidaem",
        }
        let users = store.getState().users.userList
        for (let i = 0; i < users.length; i++) {
            if (!newElem.user && newElem.user_id == users[i].id) newElem.user = users[i]
        }

        let confirmList = store.getState().document.confirmList.slice()
        confirmList.push(newElem)
        yield put({ type: 'SET_CONFIRM_LIST', confirmList })
    } catch (err) {
        console.log(err)
    }
}


//Сага удаления из листа согласования

function* removeVoter({ voteId }) {
    try {
        let res = yield call(() => fetchData(`/votings/${voteId}`, res => res, null, 'DELETE'))
        let confirmList = store.getState().document.confirmList.slice()
        let index
        for (let i = 0; i < confirmList.length; i++) {
            if (!index && confirmList[i].id == res.id) index = i
        }
        confirmList.splice(index, 1)
        yield put({ type: 'SET_CONFIRM_LIST', confirmList })
    } catch (err) {
        console.log(err)
    }
}


//Сага изменения статуса в листе согласования

function* changeConfirm({ voteId, status }) {
    try {
        let res = yield call(() => fetchData(`/votings/${voteId}?status_id=${status}`, res => res, null, 'PUT'))
        let confirmList = store.getState().document.confirmList.slice()
        let index
        for (let i = 0; i < confirmList.length; i++) {
            if (!index && confirmList[i].id == res.id) index = i
        }
        confirmList[index] = res
        yield put({ type: 'SET_CONFIRM_LIST', confirmList })
    } catch (err) {
        console.log(err)
    }
}


//Сага изменения статуса документа в ИД

function* changeDocStatus({ statusId, docId, elemType }) {
    try {
        let res
        if (docId != 0) {
            if (statusId == 1) {
                res = yield call(() => fetchData(`/document/${docId}`, res => res, null, 'DELETE'))
            } else {
                let body = JSON.stringify({ status_id: statusId })
                res = yield call(() => fetchData(`/document/${docId}`, res => res, null, 'POST', body))
            }
        } else {
            let body = JSON.stringify({ status_id: statusId })
            res = yield call(() => fetchData(`/document?type_docums=${elemType}&ispoldocum_id=${store.getState().ispoldocums.activeTr.id}`, res => res, null, 'POST', body))
        }

        let docums

        let searchMode = store.getState().ispoldocums.searchMode

        if (!searchMode) {
            docums = store.getState().ispoldocums.docs.slice()
        } else {
            docums = store.getState().ispoldocums.searchDocs.slice()
        }

        let ind = null
        for (let i = 0; i < docums.length; i++) {
            if (!ind && docums[i].id == store.getState().ispoldocums.activeTr.id) ind = i
        }

        let clone = Object.assign({}, docums[ind])

        if (statusId == 1) {
            clone[elemType] = null
        } else {
            delete clone[elemType]

            clone[elemType] = {
                ...res,
                status: {
                    ...statusObj[statusById[res.status_id]],
                    slug: statusById[res.status_id]
                }
            }
        }


        docums.splice(ind, 1, clone)

        if (searchMode) {
            yield put({ type: 'SET_SEARCH_DOCS', searchDocs: docums })
        } else {
            yield put({ type: 'ADD_ISPOLDOCUMS', docums })
        }
        yield put({ type: 'SET_ACTIVE_TR', activeTr: clone })


    } catch (err) {
        console.log(err)
    }
}

//SET_SEARCH_DOCS
//Сага загрузкт документов в ИД

function* uploadFile({ file, docId, elemType, fipId }) {
    try {
        let res
        let formData = new FormData()
        formData.append('file', file)
        formData.append('status_id', 3)
        if (docId != 0) {
            res = yield call(() => fetchData(`/document/${docId}`, res => res, null, 'POST', formData, {
                'X-Requested-With': 'XMLHttpRequest',
                'Authorization': `Bearer ${getCookie("access_token_rg")}`
            }))
        } else {
            res = yield call(() => fetchData(`/document?type_docums=${elemType}&ispoldocum_id=${fipId}`, res => res, null, 'POST', formData, {
                'X-Requested-With': 'XMLHttpRequest',
                'Authorization': `Bearer ${getCookie("access_token_rg")}`
            }))
        }

        let docums
        let searchMode = store.getState().ispoldocums.searchMode

        if (!searchMode) {
            docums = store.getState().ispoldocums.docs.slice()
        } else {
            docums = store.getState().ispoldocums.searchDocs.slice()
        }


        let ind = null

        for (let i = 0; i < docums.length; i++) {
            if (!ind && docums[i].id == fipId) ind = i
        }

        let clone = Object.assign({}, docums[ind])


        clone[elemType] = {
            ...res,
            status: {
                ...statusObj[statusById[res.status_id]],
                slug: statusById[res.status_id]
            }
        }

        docums.splice(ind, 1, clone)

        if (searchMode) {
            yield put({ type: 'SET_SEARCH_DOCS', searchDocs: docums })
        } else {
            yield put({ type: 'ADD_ISPOLDOCUMS', docums })
        }
        yield put({ type: 'SET_ACTIVE_TR', activeTr: clone })
        yield put({ type: 'SET_LOADING_TYPE', loadingType: null })

    } catch (err) {
        console.log(err)
    }
}


//Сага удаления файла из ИД

function* deleteFile({ docId, elemType, fipId }) {
    try {
        const res = yield call(() => fetchData(`/document/${docId}`, res => res, null, 'DELETE'))

        let docums = store.getState().ispoldocums.docs.slice()
        let ind = null

        for (let i = 0; i < docums.length; i++) {
            if (!ind && docums[i].id == fipId) ind = i
        }

        let clone = Object.assign({}, docums[ind])

        delete clone[elemType]

        clone[elemType] = null

        docums.splice(ind, 1, clone)

        yield put({ type: 'ADD_ISPOLDOCUMS', docums })
        yield put({ type: 'SET_ACTIVE_TR', activeTr: clone })
    } catch (err) {
        console.log(err)
    }
}



//Сага добавления титула в таблицу

function* addTitul({ name, title, orgId }) {
    try {
        const titul = yield call(() => fetchData('/ispoldocums', res => res, null, 'POST', JSON.stringify({
            fip: name,
            group_id: orgId,
            name: title
        })))

        let docums = store.getState().ispoldocums.docs.slice()
        docums.push(titul)
        yield put({ type: 'ADD_ISPOLDOCUMS', docums })

    } catch (err) {
        console.log(err)
    }
}


//Сага поиска фипов в ИД

function* searchDocs({ value }) {
    try {
        const docs = yield call(() => fetchData(`/ispoldocums?sort=desc&order=updated_at&limit=15&offset=0&find=${value}`, res => res))

        if (docs) {
            yield put({ type: 'SET_SEARCH_DOCS', searchDocs: docs })
        }

        // yield put({ type: 'SET_SEARCH_DOCS', searchDocs: docs })
        yield put({ type: 'START_SEARCH_MODE', searchValue: value })

    } catch (err) {
        console.log(err)
    }
}

function* appendSearchDocs({ ofset }) {
    try {
        let value = store.getState().ispoldocums.searchValue
        const docs = yield call(() => fetchData(`/ispoldocums?sort=desc&order=updated_at&limit=15&offset=${ofset * 15}&find=${value}`, res => res))


        if (docs.length > 0) {
            let searchDocs = store.getState().ispoldocums.searchDocs
            let result = searchDocs.concat(docs)
            yield put({ type: 'SET_SEARCH_DOCS', searchDocs: result })
        }
    } catch (err) {
        console.log(err)
    }
}

function* saveFipChange({ fip, name, group_id }) {
    try {
        let id = store.getState().fipchange.id

        let res = yield call(() => fetchData(`/ispoldocums/${id}`, res => res, null, 'PUT', JSON.stringify({
            fip,
            group_id: group_id.id,
            name
        })))

        let docums = store.getState().ispoldocums.docs.slice()
        let ind = null

        for (let i = 0; i < docums.length; i++) {
            if (!ind && docums[i].id == res.id) ind = i
        }

        let clone = Object.assign({}, docums[ind])

        clone.fip = res.fip
        clone.name = res.name
        clone.group_id = group_id

        docums.splice(ind, 1, clone)

        yield put({ type: 'ADD_ISPOLDOCUMS', docums })
        yield put({ type: 'CLEAR_FIP_CHANGE' })

    } catch (err) {
        console.log(err)
    }
}


function* deleteFip() {
    try {
        let id = store.getState().fipchange.id,
            length = store.getState().ispoldocums.docs.length
        let res = yield call(() => fetchData(`/ispoldocums/${id}`, res => res, null, 'DELETE'))
        let newFip = yield call(() => fetchData(`/ispoldocums?sort=desc&order=updated_at&limit=1&offset=${length}`, res => res[0]))


        let docums = store.getState().ispoldocums.docs.slice()
        let ind = null

        for (let i = 0; i < docums.length; i++) {
            if (!ind && docums[i].id == res.id) ind = i
        }

        docums.splice(ind, 1)
        docums.push(newFip)

        yield put({ type: 'ADD_ISPOLDOCUMS', docums })
        yield put({ type: 'CLEAR_FIP_CHANGE' })

    } catch (err) {
        console.log(err)
    }
}


function* fetchUserSubs({ userId }) {
    try {
        const res = yield call(() => fetchData(`/v2/users/${userId}/document-event-types`, res => res.documentUserEvents))

        yield put({ type: 'SET_USER_SUBS', userSubs: res })
    } catch (err) {
        console.log(err)
    }
}

function* subUser({ userId, eventType }) {
    try {
        const res = yield call(() => fetchData(`/v2/users/${userId}/document-event-types/${eventType}`, res => res.documentUserEvent, null, 'POST'))
        let clone = store.getState().subscribe.userSubs.slice()
        clone.push(res)
        yield put({ type: 'SET_USER_SUBS', userSubs: clone })
    } catch (err) {
        console.log(err)
    }
}


function* unsubUser({ userId, eventType }) {
    try {
        const res = yield call(() => fetchData(`/v2/users/${userId}/document-event-types/${eventType}`, res => res, null, 'DELETE'))
        let clone = store.getState().subscribe.userSubs.slice()
        let ind
        for (let i = 0; i < clone.length; i++) {
            if (!ind && eventType == clone[i].document_event_type_id) ind = i
        }
        clone.splice(ind, 1)
        yield put({ type: 'SET_USER_SUBS', userSubs: clone })
    } catch (err) {
        console.log(err)
    }
}

function* getOffers() {
    const offerList = yield call(() => fetchData('/v2/offer-nomenclatures?order=-id', res => res.offerNomenclatures))


    yield put({ type: 'ADD_OFFERS', offerList })
}

function* deleteOffer({ id }) {
    const res = yield call(() => fetchData(`/v2/offer-nomenclatures/${id}`, res => res, null, 'DELETE'))
    let clone = store.getState().offer.offerList.slice(),
        ind = searchByKey(clone, 'id', id)
    clone.splice(ind, 1)
    yield put({ type: 'ADD_OFFERS', offerList: clone })
}


function* createOffer({ typeFiles, title, description, file }) {
    try {
        let formData = new FormData()
        formData.append('type', typeFiles)
        formData.append('title', title)
        formData.append('description', description)
        if (typeFiles != 'empty') {
            file.forEach((elem, ind) => {
                formData.append(`file[]`, elem)
            })
        }


        const offer = yield call(() => fetchData(`/v2/offer-nomenclatures`, res => res.offerNomenclature, null, 'POST', formData, {
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': `Bearer ${getCookie("access_token_rg")}`
        }))

        if (offer) {
            let clone = store.getState().offer.offerList.slice()
            clone.unshift(offer)
            yield put({ type: 'ADD_OFFERS', offerList: clone })
            yield put({ type: 'SET_OFFER_MODE', addOfferMode: false })

        }

    } catch (err) {
        console.log(err)
    }
}


function* addGroupVoting({ votingType, groupId, item }) {
    try {
        const elem = yield call(() => fetchData(`/v2/proposal-voting-types/${votingType}/groups/${groupId}`, res => res.advertGroupVoting, null, 'POST'))
        if (elem) {
            let clone = store.getState().ispoldocums.groupVotings.slice()
            clone.push(elem)
            yield put({ type: 'APPEND_GROUP_VOTING', groupVotings: clone })
        }
    } catch (err) {
        console.log(err)
    }
}

function* removeGroupVoting({ votingType, groupId, item }) {
    try {
        const elem = yield call(() => fetchData(`/v2/proposal-voting-types/${votingType}/groups/${groupId}`, res => res, null, 'DELETE'))
        let clone = store.getState().ispoldocums.groupVotings.slice()
        let ind = clone.findIndex(elem => elem.group_id == groupId && elem.advert_voting_type_id == votingType)
        if (ind != -1) {
            clone.splice(ind, 1)
            yield put({ type: 'APPEND_GROUP_VOTING', groupVotings: clone })
        }
    } catch (err) {
        console.log(err)
    }
}

function* setVotingVal({ groupVotingId, value, groupId, item }) {
    try {
        yield put({ type: 'SET_IN_LOAD_NOTIF', inLoadNotif: { menuType: store.getState().notif.menuType, groupId, item } })
        const elem = yield call(() => fetchData(`/v2/proposal-group-votings/${groupVotingId}?value=${value}`, res => res.advertGroupVoting, null, 'POST'))
        let clone = store.getState().ispoldocums.groupVotings.slice()
        let ind = clone.findIndex(elem => elem.id == groupVotingId)
        if (ind != -1) {
            clone[ind].value = elem.value
            yield put({ type: 'APPEND_GROUP_VOTING', groupVotings: clone })
        }
        yield put({ type: 'SET_IN_LOAD_NOTIF', inLoadNotif: { menuType: null, groupId: null, item: null } })
    } catch (err) {

    }
}


// function* createOrg({ title }) {
//     try {
//         const org = yield call(() => fetchData('/usergroups', res => res, null, 'POST', JSON.stringify({ title })))
//         if (org) {
//             let orgs = store.getState().ispoldocums.orgs.slice()
//             orgs.push(org)
//             yield put({ type: 'ADD_ORGS', orgs })
//             yield put({ type: 'SET_CURRENT_ORG', currentOrg: org })
//             yield put({ type: 'ADD_NEW_ORG_MODE', isNew: false })
//             notie.alert({ type: 'success', text: 'Организация успешно сохранена' })
//         }
//     } catch (err) {
//         console.log(err)
//     }
// }


// function* changeOrg({ id, title }) {
//     try {
//         const org = yield call(() => fetchData(`/usergroups/${id}`, res => res, null, 'PUT', JSON.stringify({ title })))
//         if (org) {
//             let orgs = store.getState().ispoldocums.orgs.slice()
//             let ind = orgs.findIndex(elem => elem.id == id)
//             orgs.splice(ind, 1, org)
//             yield put({ type: 'ADD_ORGS', orgs })
//             yield put({ type: 'SET_CURRENT_ORG', currentOrg: org })
//             notie.alert({ type: 'success', text: 'Данные организации успешно изменены' })
//         }
//     } catch (err) {
//         console.log(err)
//     }
// }

function* userSelfSub({ id, body, eventTypeId, requestMethod = "PATCH" }) {
    console.log(requestMethod)
    try {
        const sub = yield call(() => fetchData(`/v2/users/${id}/document-event-types/${eventTypeId}`, res => res.documentUserEvent, null, requestMethod, JSON.stringify(body)))
        if (sub) {
            let userSubs = store.getState().subscribe.userSubs.slice()
            if (requestMethod == 'PATCH') {
                let ind = userSubs.findIndex(elem => elem.document_event_type_id == eventTypeId)
                if (ind != -1) {
                    userSubs.splice(ind, 1, sub)
                }
            } else {
                userSubs.push(sub)
            }
            yield put({ type: 'SET_USER_SUBS', userSubs: userSubs })
        }
    } catch (err) {
        console.log(err)
    }
}

function* changeUserCompIds({ userId, compId, typeOfAction }) {
    try {
        let user = store.getState().users.userList.slice().find(elem => elem.id === userId)
        if (user) {
            let compIds = user.company_ids
            if (typeOfAction === 'remove') {
                let index = compIds.findIndex(elem => elem === compId)
                if (index) compIds.splice(index, 1)
            } else {
                compIds.push(compId)
            }
            let body = {
                company_ids: compIds
            }
            const res = yield call(() => fetchData(`/users/${userId}`, res => res, null, 'PUT', JSON.stringify(body)))
            if (res) {
                let users = store.getState().users.userList.slice()
                let ind = users.findIndex(elem => elem.id == userId)
                users.splice(ind, 1, res)
                const lowRoles = users.filter(elem => {
                    return (elem.roles[0] && elem.roles[0].id > 2)
                })
                yield put({ type: 'ADD_USERS', userList: users, lowRoles })
            }
        }
    } catch (err) {
        console.log(err)
    }
}

function* readIdComments({ idArr }) {
    const res = yield all([
        ...idArr.map(id => call(() => fetchData(`/v2/notifications/${id}`, res => res, null, 'PATCH')))
    ])
    yield put({ type: 'APPEND_NOTIF_LIST', ofset: 0 })

    const notifs = yield call(() => fetchData(`/v2/notifications?offset=0&limit=20`, res => res.notifications))
    yield put({ type: 'SET_NOTIF_LIST', notifList: notifs })



    let activeTr = Object.assign({}, store.getState().ispoldocums.activeTr),
        docums = store.getState().ispoldocums.docs.slice()
    delete activeTr.commentNotifications
    let activeIndex = docums.findIndex(doc => doc.id === activeTr.id)
    if (activeIndex !== -1) {
        docums.splice(activeIndex, 1, activeTr)
    }
    yield put({ type: 'ADD_ISPOLDOCUMS', docums })
    // yield put({ type: 'SET_ACTIVE_TR', activeTr: activeTr })
} 