import React, { useState, useRef, useEffect } from 'react';
import ScrollArea from 'react-scrollbar'
import { connect } from 'react-redux';


const NotifPlate = ({ notifList, docTypes, docActions, docEventTypes, setNotifDoc, appendNotifList, watchNotif, setNotifType, speed, notifClass, search }) => {

    const [counter, setCounter] = useState(0)



    function notifClickHandler(doc, id, notifType, notifElem) {
        let docType = doc == 'ispoldocum' ? 'ispoldocums' : doc.replace('docum', '')
        if (notifElem.document_event.data.ispoldocum) {
            search(notifElem.document_event.data.ispoldocum.fip, 0)
        }
        setNotifDoc(docType)
        if (id) watchNotif(id)
        if (notifType) setNotifType(notifType)
    }


    return (
        <div className={`notif_plate ${notifClass}`}>
            <h2>Уведомления</h2>
            <ScrollArea
                speed={speed}
                horizontal={false}
                className="notif_container"
                vertical={true}
                contentClassName="target_class"
                smoothScrolling={true}
                verticalContainerStyle={{ width: 6 }}
                verticalScrollbarStyle={{ width: 6, marginLeft: 0 }}
                stopScrollPropagation={true}
                topPosition={100}
                onScroll={e => {
                    if (!notifList || notifList.length == 0) return
                    if (e.realHeight - e.containerHeight - e.topPosition == 0) {
                        if ((counter + 1) * 20 == notifList.length) {
                            appendNotifList(counter + 1)
                            setCounter(counter + 1)
                        }
                    }
                }}
            >
                {notifList.map(elem => {
                    let userName = `${elem.document_event.user.sname} ${elem.document_event.user.name[0]}. ${elem.document_event.user.lname[0]}.`,
                        action = docActions[docEventTypes[elem.document_event.document_event_type_id].document_action_id].title,
                        doc = docTypes ? docTypes.filter(el => el.id == docEventTypes[elem.document_event.document_event_type_id].document_type_id)[0].title : '',
                        docName = docTypes.filter(el => el.id == docEventTypes[elem.document_event.document_event_type_id].document_type_id)[0].name,
                        id = elem.read_at ? null : elem.id,
                        actionId = docEventTypes[elem.document_event.document_event_type_id].document_action_id,
                        type = actionId == 3 ? 'comment' : [4, 5].includes(+actionId) ? 'confirm' : null

                    return (
                        <div className={`notif ${elem.read_at ? 'watched' : 'not_watched'}`} key={elem.id}>
                            <div onClick={() => watchNotif(id)} className="notif_ind"></div>
                            <div className="notif_body" onClick={() => notifClickHandler(docName, id, type, elem)}>
                                <span className='accent'>{userName}</span>
                                {` ${action} в разделе `}
                                <span className="accent">{doc}</span>
                            </div>
                        </div>)
                })}
            </ScrollArea>
        </div>
    )
}



const mapStateToProps = state => {
    return {
        notifList: state.notif.notifList,
        docTypes: state.subscribe.docTypes,
        docActions: state.subscribe.docActions,
        docEventTypes: state.subscribe.documentEventTypes,
        speed: state.auth.speed
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setNotifDoc: notifDoc => dispatch({ type: 'SET_NOTIF_DOC', notifDoc }),
        appendNotifList: ofset => dispatch({ type: 'APPEND_NOTIF_LIST', ofset }),
        watchNotif: (id) => dispatch({ type: 'WATCH_NOTIF', id }),
        setNotifType: (notifType) => dispatch({ type: 'SET_NOTIF_TYPE', notifType }),
        search: (value, ofset) => dispatch({ type: 'SEARCH_DOCS', value, ofset })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NotifPlate)