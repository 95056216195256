import React, { useState } from 'react';



const OfferFileElem = ({ arr, setFunc, ind }) => {

    const [hover, setHover] = useState(false)

    function changeFileHandler(file, arr, setFunc, index) {
        let clone = arr.slice()
        clone[index] = file
        setFunc(clone)
    }

    return (
        <div style={{ background: hover ? '#e9eff5' : '' }} className="file"
            onDragOver={e => e.preventDefault()}
            onDragEnter={e => setHover(true)}
            onDragLeave={e => { 
                if (e.relatedTarget.nodeType == 3 || e.relatedTarget.closest('.file')) return 
                setHover(false) 
            }}
            onDrop={e => {
                e.preventDefault()
                changeFileHandler(e.dataTransfer.files[0], arr, setFunc, ind)
                setHover(false)
            }}
        >
            <div className={`tooltip ${arr[ind] ? 'elem_with_file' : ''}`}>{arr[ind] ? arr[ind].name : 'Перетяните файл или'}</div>
            {arr[ind] && <div className="del_file" onClick={() => changeFileHandler(null, arr, setFunc, ind)}>Удалить</div>}
            <label>
                {arr[ind] ? 'Заменить' : 'Выберите'}
                <input
                    onChange={e => changeFileHandler(e.currentTarget.files[0], arr, setFunc, ind)}
                    type="file" />
            </label>
        </div>
    )
}

export default OfferFileElem