import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Offer from './Offer'
import AddOffer from './AddOffer'
import ScrollArea from 'react-scrollbar'


const OfferPlate = ({ getOffers, offerList, addOfferMode, setMode, speed, maxRole }) => {



    useEffect(() => {
        getOffers()
    }, [getOffers])

    return (
        <div className="offer_plate">
            <div className="offer_title">
                <h1>{addOfferMode ? 'Внести предложение' : 'Предложения'}</h1>
                {(maxRole < 4) && <div onClick={() => setMode(!addOfferMode)} className="add_offer">{addOfferMode ? 'Отменить' : 'Внести предложение'}</div>}
            </div>
            <ScrollArea
                speed={speed}
                horizontal={false}
                vertical={true}
                className="offer_list"
                smoothScrolling={true}
                verticalContainerStyle={{ width: 6 }}
                verticalScrollbarStyle={{ width: 6, marginLeft: 0 }}
                stopScrollPropagation={true}
            >
                {addOfferMode ? <AddOffer /> : (
                    <div>
                        {offerList.map(elem => (
                            <Offer
                                key={elem.id}
                                title={elem.title}
                                gist={elem.description}
                                date={elem.created_at}
                                files={elem.files}
                                id={elem.id}
                                userId={elem.userId}
                            />))}
                    </div>
                )
                }
            </ScrollArea>
        </div >
    )
}

const mapStateToProps = state => {
    return {
        offerList: state.offer.offerList,
        addOfferMode: state.offer.addOfferMode,
        speed: state.auth.speed,
        maxRole: state.auth.maxRole
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getOffers: () => dispatch({ type: 'GET_OFFERS' }),
        setMode: mode => dispatch({type: 'SET_OFFER_MODE', mode})
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(OfferPlate)