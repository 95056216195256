import React from 'react';
import ScrollArea from 'react-scrollbar'
import { connect } from 'react-redux';
import MeetingElem from './MeetingElem'




const MeetingContent = ({speed}) => {
    return (
        <div className="meeting_content">
            <h1>Собрания</h1>
            <ScrollArea
                speed={speed}
                horizontal={false}
                className="meeting_list"
                vertical={true}
                style={{ height: 'calc(100% - 80px)' }}
                smoothScrolling={true}
                verticalContainerStyle={{ width: 6, right: 8 }}
                verticalScrollbarStyle={{ width: 6, marginLeft: 0 }}
                stopScrollPropagation={true}
            >
                <MeetingElem/>
                <MeetingElem/>
                <MeetingElem/>
            </ScrollArea>
            <div className="meeting_content_actions">
                <div className="btn">Добавить</div>
            </div>
        </div>
    )
}


const mapStateToProps = state => {
    return {
        speed: state.auth.speed
    }
}


export default connect(mapStateToProps)(MeetingContent)