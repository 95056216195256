import React from 'react';
import { connect } from 'react-redux';


const DefaultMenu = ({ typeOfPlate, type, activeTr, idDocList, setActive, setType, typeLoc, notifDoc }) => {
    return (
        <ul>
            {(notifDoc || typeLoc) == 'bc' && <li className={typeOfPlate == 'enumeration' ? 'active' : ''} onClick={() => setType('enumeration')}>Перечень</li>}
            {(notifDoc || typeLoc) == 'bc' && <li className={typeOfPlate == 'factories' ? 'active' : ''} onClick={() => setType('factories')}>Заводы</li>}
            <li className={(typeOfPlate == 'comment') ? 'active' : ''} onClick={() => {
                if (type == 'ispoldocums' && !activeTr && idDocList.length > 0) {
                    setActive(idDocList[0])
                }
                setType('comment')
            }}>Комментарий</li>
            <li className={typeOfPlate == 'confirm' ? 'active' : ''} onClick={() => setType('confirm')}>Согласование</li>
            {(notifDoc || typeLoc) == 'nomen' && <li className={typeOfPlate == 'offer' ? 'active' : ''} onClick={() => setType('offer')}>Предложения</li>}
        </ul>
    )
}

const mapStateToProps = state => {
    return {
        type: state.document.doctype,
        activeTr: state.ispoldocums.activeTr,
        idDocList: state.ispoldocums.docs,
        notifDoc: state.notif.notifDoc
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setActive: (activeTr) => dispatch({ type: 'SET_ACTIVE_TR', activeTr }),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(DefaultMenu)