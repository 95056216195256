import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import TextComment from './TextComment'
import CommentList from './CommentList'



const CommentPlate = ({ doctype, getComments, commentList, sendComment, activeTr, parentComment, users, delParent, selectedRmMonth }) => {

    const [authorName, setAuthor] = useState('')

    useEffect(() => {
        if (users.length > 0) {
            if (doctype === 'ispoldocums') {
                if (activeTr) getComments(activeTr.id)
            } else {
                getComments()
            }
        }
    }, [doctype, activeTr, users, getComments, selectedRmMonth])

    useEffect(() => {
        if (parentComment) {
            for (let i = 0; i < users.length; i++) {
                if (users[i].id === parentComment.user_id) setAuthor(`${users[i].sname} ${users[i].name}`)
            }
        }
    }, [parentComment, users])


    return (
        <div className="comment_plate">
            <h1>Комментарии</h1>
            <div className="parent_comment" style={{visibility: parentComment ? '' : 'hidden', height: 34}}>
                <div style={{width: '80%'}}>
                    <div className="parent_author">{parentComment && authorName}</div>
                    <div className="parent_body">{parentComment && JSON.parse(parentComment.body)}</div>
                </div>
                <div onClick={() =>delParent()} className="del_parent"></div>
            </div>
            <TextComment sendHandler={text => {
                    sendComment(text)
                }} />
            {(commentList.length > 0) && <CommentList />}
        </div>
    )
}



const mapStateToProps = state => {
    return {
        doctype: state.document.doctype,
        activeTr: state.ispoldocums.activeTr,
        commentList: state.comments.commentList,
        parentComment: state.comments.parentComment,
        users: state.users.userList,
        selectedRmMonth: state.document.selectedRmMonth
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getComments: (partId) => dispatch({ type: 'FETCH_COMMENTS', partId }),
        sendComment: (text) => dispatch({ type: 'SEND_COMMENT', text }),
        delParent: () => dispatch({type: 'SET_PARENT_COMMENT', parent: null})
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CommentPlate)


