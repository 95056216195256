import React, { useState } from 'react';
import Collapse from '@material-ui/core/Collapse';


const MeetingElem = () => {

    const [opened, setOpened] = useState(false)

    return (
        <div className="meeting_elem">
            <div onClick={() => setOpened(!opened)} className="meeting_head">
                <div>
                    <div className="meeting_name">Модерницация ПТК УНЦ</div>
                    <div className="meeting_date">20.04.2020</div>
                </div>
                <div className={`meeting_arrow ${opened && 'rotated'}`}></div>
            </div>
            <Collapse in={opened} timeout="auto" unmountOnExit>
                <h1>Протокол</h1>
                <div className="protocol">
                    <div className="protocol_elem">
                        <div className="elem_time">13:00</div>
                        <div className="elem_content">Заседание началось</div>
                    </div>
                    <div className="protocol_elem">
                        <div className="elem_time">14:00</div>
                        <div className="elem_content">
                            <div>Внесенно предложение о соединении РГ ЦО и Gipro от Алексея Голикова</div>
                            <div className="protocol_actions">
                                <div>Голосование</div>
                                <div>Комментировать</div>
                            </div>
                        </div>
                    </div>
                    <div className="protocol_elem">
                        <div className="elem_time">15:00</div>
                        <div className="elem_content">
                            <div>Внесенно предложение о смене названия Isexciteit на easy task</div>
                            <div className="protocol_actions">
                                <div>Голосование</div>
                                <div>Комментировать</div>
                            </div>
                        </div>
                    </div>
                    <div className="protocol_elem end_elem">
                        <div className="elem_time"></div>
                        <div className="elem_content">Время окончания: 15:30</div>
                    </div>
                </div>
            </Collapse>
        </div>
    )
}


export default MeetingElem