import React from 'react'


const FactoriesPlate = () => {
	return (
		<div className="factories-plate">
			<h1>Комментарии</h1>
				<div className="parent_comment">
						<div style={{width: '80%'}}>
								<div className="parent_author">ds</div>
								<div className="parent_body">ds</div>
						</div>
				</div>
		</div>
	)
}

export default FactoriesPlate